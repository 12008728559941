import React, { useEffect, useState } from 'react';
import { useIsMutating } from 'react-query';
import { useIntl } from 'react-intl';
import { useClientStore } from '../../store/store';
import AppliedFilterRibbon from '../../components/AppliedFiltersRibbon';
import { Box } from '../../components/Box';
import Button from '../../components/Button';
import LocationFilterRibbon from '../../components/LocationFilterRibbon';
import { L10n } from '../../components/L10n';
import RedirectHandler from '../../components/RedirectHandler';
import Typography from '../../components/Typography';
import PageNavigation from '../../components/PageNavigation';
import PageTitleSubtitle from '../../components/PageTitleSubtitle';
import Pagination from '../../components/Pagination';
import SearchResultCard from '../../components/SearchResultCard';
import Loader from '../../components/Loader';
import useSearch from '../../utils/search/searchEvents';
import { showToast } from '../../components/Toast';
import { useCreateSessionHold } from '../../api/Session-hold';
import Card from '../../components/Card';

const PAGE_SIZE = 8;

const LocationSelection = () => {
  const isMutating = useIsMutating();
  const intl = useIntl();
  const {
    apptDetails,
    hideModal,
    showModal,
    updateModal,
    updateUiState,
    eventSession,
    UrlSpecifiedEvent,
    updateApptDetails,
  } = useClientStore((state) => ({
    apptDetails: state.apptDetails,
    hideModal: state.hideModal,
    showModal: state.showModal,
    updateModal: state.updateModal,
    updateUiState: state.updateUiState,
    eventSession: state.apptDetails.Event_Session__c,
    UrlSpecifiedEvent: state.apptDetails.UrlSpecifiedEvent,
    updateApptDetails: state.updateApptDetails,
  }));
  const [productState, setProductState] = useState(apptDetails.products);
  const [visibleRecords, setVisibleRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const searchStr = useClientStore((state) => state.uiState.searchStr);
  const { results, isLoading } = useSearch(searchStr, null, productState);
  useEffect(() => {
    setVisibleRecords(results.slice(0, PAGE_SIZE));
    setCurrentPage(0);
  }, [results, searchStr]);

  const isMobile = window.screen.width < 767;
  const toastPosition = isMobile ? 'top-center' : 'bottom-left';

  const showErrorToast = () => {
    showToast(
      intl.formatMessage({
        id: 'page.location_selection.select_event',
      }),
      'error',
      toastPosition,
      undefined,
      true,
      true
    );
  };

  const showHoldErrorToast = () => {
    showToast(
      intl.formatMessage({
        id: 'page.location_selection.hold_error',
      }),
      'error',
      toastPosition,
      undefined,
      true,
      true
    );
  };

  const onSessionHoldSuccess = () => {
    updateModal('appointmentExpirationModal', {
      startTimer: true,
    });
  };

  const [ribbonState, setRibbonState] = useState({ isExpanded: false });
  const locationFilterButton = (
    <Button
      handleClick={() => setRibbonState({ isExpanded: !ribbonState.isExpanded })}
      size="noPadding"
      styling="transparent"
    >
      <Typography tag="p" variant="primarySmall" color={{ color: 'highlight' }}>
        {ribbonState.isExpanded ? (
          <L10n tokenID="general.cancel" />
        ) : (
          <L10n tokenID="general.change" />
        )}
      </Typography>
    </Button>
  );

  const handleButtonClick = () => {
    updateModal('locationFilterModal', {
      handleClose: hideModal,
      productState,
      setProductState,
    });
    showModal();
  };
  const hold = useCreateSessionHold();
  const handleNext = async () => {
    if (eventSession) {
      await hold.mutateAsync(null, {
        onSuccess: onSessionHoldSuccess,
        onError: showHoldErrorToast,
      });
      updateUiState('validPage', true);
      updateApptDetails('products', productState);
    } else {
      showErrorToast();
    }
  };

  return (
    <>
      <RedirectHandler pageNumber={5} />
      {(isLoading || isMutating > 0) && <Loader />}
      <Box
        data-testid="LocationSelection"
        gridColumn={['1/5', '1 / 13', 'auto /span 13']}
        display="grid"
        gridGap={['large', 'xLarge']}
      >
        <PageTitleSubtitle
          title={<L10n tokenID="page.location_selection.select_pharmacy" />}
          subtitle={<L10n tokenID="page.location_selection.subtitle" />}
        />
        <Box display={['block', 'flex']} gridGap="xxxSmall">
          {!UrlSpecifiedEvent && (
            <LocationFilterRibbon
              expanded={ribbonState.isExpanded}
              setExpanded={setRibbonState}
              button={locationFilterButton}
            />
          )}
          {(isMobile || !ribbonState.isExpanded) && (
            <AppliedFilterRibbon
              filteredProducts={Object.keys(productState)}
              handleClick={handleButtonClick}
            />
          )}
        </Box>
        {!isLoading && !UrlSpecifiedEvent && results.length > 0 && (
          <Typography tag="p" variant={['boldSmall']} space={{ px: ['', 'medium'] }}>
            <L10n tokenID="page.location_selection.stores" values={{ count: results.length }} />
          </Typography>
        )}
        {!isLoading && !UrlSpecifiedEvent && results.length === 0 && (
          <Card variant="error">
            <Typography tag="p" variant={['boldSmall']} space={{ px: ['', 'medium'] }}>
              <L10n tokenID="page.location_selection.no_results_error" />
            </Typography>
          </Card>
        )}
        <Box display="grid" gridGap={['large', 'medium']}>
          {visibleRecords.map((rec) => (
            <SearchResultCard key={rec.event.Id} pharmacy={rec} filteredProducts={productState} />
          ))}
        </Box>
        {results.length > PAGE_SIZE && (
          <Pagination
            currentPage={currentPage}
            numberOfItems={5}
            setCurrentPage={setCurrentPage}
            setVisibleRecords={setVisibleRecords}
            products={productState}
            pageSize={PAGE_SIZE}
            records={results}
          />
        )}
      </Box>
      <PageNavigation handleNext={handleNext} />
    </>
  );
};

export default LocationSelection;
